import React from "react";
import {
  getCategoriesFromProducts,
  slugify,
  shouldShowProduct
} from "../util/products";
import "./product-grid.scss";
import { attributeIcons } from "../../data/content/products";

const AttributeIcon = ({ attribute }) =>
  (attributeIcons[attribute] && (
    <span className="attribute-icon">
      <img src={attributeIcons[attribute]} alt={attribute} />
    </span>
  )) ||
  null;

const ProductItem = ({
  name,
  allergenes,
  image,
  category,
  incredients,
  kpis,
  attributes,
  longText,
  htmlText,
  show
}) => (
  <div className={`product-item ${show ? "" : "filter-hidden"}`} tabIndex="0">
    <div className="inner-wrapper">
      <div
        className="image-wrapper"
        style={{ backgroundImage: `url(${image})` }}
      >
        <div className={`overlay-wrapper ${longText ? "long-text" : ""}`}>
          {incredients && (
            <p
              className="incredients"
              dangerouslySetInnerHTML={
                htmlText ? { __html: incredients } : null
              }
            >
              {!htmlText ? incredients : null}
            </p>
          )}
          {kpis && <p className="kpis">{kpis}</p>}
          <div className="attributes-wrapper">
            {attributes &&
              attributes.map(attribute => (
                <AttributeIcon attribute={attribute} key={attribute} />
              ))}
          </div>
        </div>
      </div>
      <div className="name-wrapper">
        <h4 className="name">{name}</h4>
        {allergenes && <sup className="allergenes">{allergenes}</sup>}
      </div>
    </div>
  </div>
);

class ProductGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentFilter: null
    };

    this.doFilter = this.doFilter.bind(this);
  }

  doFilter(filter) {
    this.setState({ currentFilter: filter });
  }

  render() {
    const categories = getCategoriesFromProducts(this.props.products);
    return (
      <div className="grid-wrapper">
        {this.props.showFilter && (
          <ul className="filters">
            <li
              key="all"
              className={this.state.currentFilter === null ? "active" : ""}
            >
              <button onClick={() => this.doFilter(null)}>
                {this.props.allFilterLabel}
              </button>
            </li>
            {Object.keys(categories).map(category => (
              <li
                key={category}
                className={
                  this.state.currentFilter === category ? "active" : ""
                }
              >
                <button onClick={() => this.doFilter(category)}>
                  {categories[category]}
                </button>
              </li>
            ))}
          </ul>
        )}
        <div
          className="grid"
          ref={el => {
            this.grid = el;
          }}
        >
          {this.props.products.map((product, key) => (
            <ProductItem
              {...product}
              key={key}
              show={shouldShowProduct(this.state.currentFilter, product)}
            />
          ))}
        </div>
      </div>
    );
  }
}

export default ({
  headline,
  showFilter,
  allFilterLabel,
  subline,
  products,
  backgroundDark,
  ...props
}) => (
  <section
    className={`product-grid ${props.className || ""} ${
      backgroundDark ? "background-dark" : ""
    }`}
  >
    <div className="text-wrapper">
      <h2 className="headline">
        {headline} {subline && <small className="subline">{subline}</small>}
      </h2>
    </div>

    <ProductGrid
      products={products}
      showFilter={showFilter}
      allFilterLabel={allFilterLabel}
    />
  </section>
);
