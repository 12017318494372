import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Intro from "../components/intro";
import ProductGrid from "../components/product-grid";

import { smoothies, snacks } from "../../data/content/products";

const SmoothiesSnacksPage = () => (
  <Layout>
    <SEO title="Produkte" />
    <Intro
      headline="Trink dich YEAH!"
      image={require("../images/neu/bar-full.jpg")}
      noBottomPadding
    >
      <p>
        Mit unseren Juices und Smoothies beweisen wir, dass Gesundheit und
        Genuss genauso gut zusammenpassen, wie Apfel und Banane. Und: Dass
        frische, reife Früchte so süß und lecker sind, dass sie nicht das
        kleinste Löffelchen Zucker benötigen, um dich glücklich zu machen.
      </p>
    </Intro>
    <ProductGrid
      headline="Unsere Smoothies"
      subline="(alle ohne Zusatz von Zucker und chemischen Zusatzstoffen)"
      showFilter
      allFilterLabel="Alle Smoothies"
      backgroundDark
      products={smoothies}
    />
    <ProductGrid
      headline="Unsere Snacks"
      subline="(Nur in den Filialen SCS und Hauptbahnhof erhältlich)"
      products={snacks}
    />
  </Layout>
);

export default SmoothiesSnacksPage;
