const slugify = text =>
  text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(/[^\w\-]+/g, "") // Remove all non-word chars
    .replace(/\-\-+/g, "-") // Replace multiple - with single -
    .replace(/^-+/, "") // Trim - from start of text
    .replace(/-+$/, ""); // Trim - from end of text

const getCategoriesFromProducts = products => {
  const categories = {};
  products.forEach(product => {
    if (product.category && product.category !== "") {
      const slugifiedCategory = slugify(product.category);
      if (!categories[slugifiedCategory]) {
        categories[slugifiedCategory] = product.category;
      }
    }
  });

  return categories;
};

const shouldShowProduct = (currentFilter, product) => {
  if (currentFilter === null) return true;
  return currentFilter === slugify(product.category);
};

export { slugify, getCategoriesFromProducts, shouldShowProduct };
