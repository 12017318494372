const smoothies = [
  {
    name: "Ibiza",
    incredients: "Beeren, Apfel, Orange, Birne, Banane",
    kpis: "100ml, 60 kcal",
    category: "Pure Fruit Smoothies",
    attributes: ["vegan", "lactose", "gluten"],
    image: require("../../src/images/produkte/ibiza.jpg")
  },
  {
    name: "Honolulu",
    incredients: "Ananas, Mango, Kiwi, Orange, Banane, Spinat",
    kpis: "100ml, 62 kcal",
    category: "Pure Fruit Smoothies",
    attributes: ["vegan", "lactose", "gluten"],
    image: require("../../src/images/produkte/honolulu.jpg")
  },
  {
    name: "Slim Jim",
    incredients: "Grapefruit, Erdbeere, Zitrone, Apfel",
    kpis: "100ml, 51 kcal",
    category: "Pure Fruit Smoothies",
    attributes: ["vegan", "lactose", "gluten"],
    image: require("../../src/images/produkte/slimjim.jpg")
  },
  {
    name: "Tahiti",
    incredients: "Karotte, Apfel, Mango, Kiwi, Orange",
    kpis: "100ml, 56 kcal",
    category: "Pure Fruit Smoothies",
    attributes: ["vegan", "lactose", "gluten"],
    image: require("../../src/images/produkte/tahiti.jpg")
  },
  {
    name: "Bora Bora",
    incredients: "Maracuja, Zitrone, Ananas, Mango, Orange",
    kpis: "100ml, 56 kcal",
    category: "Pure Fruit Smoothies",
    attributes: ["vegan", "lactose", "gluten"],
    image: require("../../src/images/produkte/borabora.jpg")
  },
  {
    name: "Koh Samui",
    incredients: "Kokosmilch, Ananas, Banane, Orange, Kokos",
    kpis: "100ml, 76 kcal",
    category: "Pure Fruit Smoothies",
    attributes: ["vegan", "lactose", "gluten"],
    image: require("../../src/images/produkte/kohsamui.jpg")
  },
  {
    name: "Korsika",
    incredients: "Erdbeere, Apfel, Banane",
    kpis: "100ml, 67 kcal",
    category: "Pure Fruit Smoothies",
    attributes: ["vegan", "lactose", "gluten"],
    image: require("../../src/images/produkte/korsika.jpg")
  },
  {
    name: "Don Vito",
    incredients: "Karotte, Apfel, Orange",
    kpis: "100ml, 50 kcal",
    category: "Juices",
    attributes: ["vegan", "gluten", "lactose"],
    image: require("../../src/images/produkte/donvito.jpg")
  },
  {
    name: "Hot & Healthy",
    incredients: "Karotte, Apfel, Orange, Kurkuma, schw. Pfeffer, Zitrone",
    kpis: "100ml, 52 kcal",
    category: "Juices",
    attributes: ["vegan", "gluten", "lactose"],
    image: require("../../src/images/produkte/hothealthy.jpg")
  },
  {
    name: "Wake Up Call",
    incredients: "Rote Rübe, Sellerie, Ingwer, Zitrone, Karotte, Apfel",
    kpis: "100ml, 44 kcal",
    category: "Juices",
    attributes: ["vegan", "gluten", "lactose"],
    allergenes: "L",
    image: require("../../src/images/produkte/wakeupcall.jpg")
  },
  {
    name: "Winning Team",
    incredients: "Apfel, Karotte, Ingwer",
    kpis: "100ml, 50 kcal",
    category: "Juices",
    attributes: ["vegan", "gluten", "lactose"],
    image: require("../../src/images/produkte/winningteam.jpg")
  },
  {
    name: "Anti Flu",
    incredients: "Ingwer, Zitrone, Apfel, Orange",
    kpis: "100ml, 55 kcal",
    category: "Juices",
    attributes: ["vegan", "gluten", "lactose"],
    image: require("../../src/images/produkte/antiflu.jpg")
  },
  {
    name: "Orangensaft",
    incredients: "Orangensaft",
    kpis: "100ml, 47 kcal",
    category: "Pure Juices",
    attributes: ["vegan", "gluten", "lactose"],
    image: require("../../src/images/produkte/orangensaft.jpg")
  },
  {
    name: "Karottensaft",
    incredients: "Karottensaft",
    kpis: "100ml, 39 kcal",
    category: "Pure Juices",
    attributes: ["vegan", "gluten", "lactose"],
    image: require("../../src/images/produkte/karottensaft.jpg")
  },
  {
    name: "Apfelsaft",
    incredients: "Apfelsaft",
    kpis: "100ml, 64 kcal",
    category: "Pure Juices",
    attributes: ["vegan", "gluten", "lactose"],
    image: require("../../src/images/produkte/apfelsaft.jpg")
  },
  {
    name: "Grapefruitsaft",
    incredients: "Grapefruitsaft",
    kpis: "100ml, 44 kcal",
    category: "Pure Juices",
    attributes: ["vegan", "gluten", "lactose"],
    image: require("../../src/images/produkte/grapefruitsaft.jpg")
  },
  {
    name: "Glücksbringer",
    incredients:
      "Rohkakao, Mandelmilch, Kokosmilch, Müsli, Banane, Agavendicksaft, Ice",
    kpis: "100ml, 111 kcal",
    category: "Amazing Vegan Smoothies",
    allergenes: "A, E, G, H",
    attributes: ["vegan", "lactose"],
    image: require("../../src/images/produkte/gluecksbringer.jpg")
  },
  {
    name: "Grünes Gold",
    incredients: "Datteln, Mandelmilch, Spinat, Banane, Matcha, Ice",
    kpis: "100ml, 42 kcal",
    category: "Amazing Vegan Smoothies",
    allergenes: "H",
    attributes: ["vegan", "lactose", "gluten"],
    image: require("../../src/images/produkte/gruenesgold.jpg")
  },
  {
    name: "Liquid Breakfast",
    incredients: "Mandeln, Datteln, Avocado, Apfel, Zimt, Ice",
    kpis: "100ml, 85 kcal",
    category: "Amazing Vegan Smoothies",
    attributes: ["vegan", "lactose", "gluten"],
    allergenes: "H",
    image: require("../../src/images/produkte/liquidbreakfast.jpg")
  },
  {
    name: "Happy Flirt",
    incredients: "Himbeere, Apfel, Banane, Joghurt, Ice",
    kpis: "100ml, 65 kcal",
    category: "Joghurt Smoothies",
    allergenes: "G",
    attributes: ["gluten"],
    image: require("../../src/images/produkte/happyflirt.jpg")
  },
  {
    name: "Heartbreaker",
    incredients: "Mango, Maracuja, Apfel, Joghurt, Ice",
    kpis: "100ml, 63 kcal",
    category: "Joghurt Smoothies",
    allergenes: "G",
    attributes: ["gluten"],
    image: require("../../src/images/produkte/heartbreaker.jpg")
  },
  {
    name: "King Coconut",
    incredients: "Banane, Kokos, Milch, Ice",
    kpis: "100ml, 84 kcal",
    category: "Joghurt Smoothies",
    allergenes: "G",
    attributes: ["gluten"],
    image: require("../../src/images/produkte/kingcoconut.jpg")
  },
  {
    name: "Queen Berry",
    incredients: "Himbeere, Banane, weiße Schoko, Milch, Ice",
    kpis: "100ml, 65 kcal",
    category: "Joghurt Smoothies",
    allergenes: "G",
    attributes: ["gluten"],
    image: require("../../src/images/produkte/queenberry.jpg")
  },
  {
    name: "Miss Einhorn",
    incredients:
      "weiße Schoko, Himbeere, Banane, Milch, Schlagobers, Marshmallows, Zuckerstreusel",
    kpis: "100ml, 78 kcal",
    category: "Deine Schokoladenseite",
    allergenes: "G",
    attributes: ["gluten"],
    image: require("../../src/images/produkte/misseinhorn.jpg")
  },
  {
    name: "Marsianer",
    incredients:
      "Mars Riegel, Milch, Banane, Mandeln, Schlagobers, weiße Schoko",
    kpis: "100ml, 153 kcal",
    category: "Deine Schokoladenseite",
    allergenes: "G, H, F",
    attributes: [],
    image: require("../../src/images/produkte/marsianer.jpg")
  },
  {
    name: "Green Garden Detox",
    incredients: "Zucchini, Zitrone, Apfel, Birne, Petersilie, Ingwer, Ice",
    kpis: "100ml, 44 kcal",
    category: "Green Detox Smoothies",
    attributes: ["vegan", "lactose", "gluten"],
    image: require("../../src/images/produkte/greengardendetox.jpg")
  },
  {
    name: "Wild Lili",
    incredients:
      "Aloe Vera, Birne, Apfel, Kiwi, Petersilie, Spinat, Sellerie, Ice",
    kpis: "100ml, 44 kcal",
    category: "Green Detox Smoothies",
    attributes: ["vegan", "lactose", "gluten"],
    allergenes: "L",
    image: require("../../src/images/produkte/wildlili.jpg")
  },
  {
    name: "Early Bird",
    incredients:
      "Salat, Maracuja, Banane, Ingwer, Birne, Kurkuma, schw. Pfeffer, Ice",
    kpis: "100ml, 56 kcal",
    category: "Green Detox Smoothies",
    attributes: ["vegan", "lactose", "gluten"],
    image: require("../../src/images/produkte/earlybird.jpg")
  },
  {
    name: "Green Energy",
    incredients: "Ananas, Salat, Zitrone, Minze, Apfel, Ice",
    kpis: "100ml, 49 kcal",
    category: "Green Detox Smoothies",
    attributes: ["vegan", "lactose", "gluten"],
    image: require("../../src/images/produkte/greenenergy.jpg")
  },
  {
    name: "Body Cleaner",
    incredients: "Ananas, Petersilie, Gurke, Minze, Orange, Ice",
    kpis: "100ml, 41 kcal",
    category: "Green Detox Smoothies",
    attributes: ["vegan", "lactose", "gluten"],
    image: require("../../src/images/produkte/bodycleaner.jpg")
  },
  {
    name: "Frau Muntermacher",
    incredients: "Guarana, Mango, Orange, Minze, Ice",
    kpis: "100ml, 47 kcal",
    category: "Energy Smoothies",
    attributes: ["vegan", "lactose", "gluten"],
    image: require("../../src/images/produkte/fraumuntermacher.jpg")
  },
  {
    name: "Schönheitskönigin",
    incredients: "Beeren, Acai, Müsli, Banane, Apfel, Leinsamen, Orange, Ice",
    kpis: "100ml, 79 kcal",
    category: "Energy Smoothies",
    allergenes: "A, E, H",
    attributes: ["vegan", "lactose"],
    image: require("../../src/images/produkte/schoenheitskoenigin.jpg")
  },
  {
    name: "Meister des Chi",
    incredients:
      "Avocado, Ananas, Apfel, Ingwer, Minze, Chili Salz, Weizengras, Ice",
    kpis: "100ml, 60 kcal",
    category: "Energy Smoothies",
    attributes: ["vegan", "lactose", "gluten"],
    image: require("../../src/images/produkte/meisterdeschi.jpg")
  },
  {
    name: "Kraftprotz",
    incredients: "Gurke, Avocado, Spinat, Hanfprotein, Minze, Apfel, Ice",
    kpis: "100ml, 51 kcal",
    category: "Energy Smoothies",
    attributes: ["vegan", "lactose", "gluten"],
    image: require("../../src/images/produkte/kraftprotz.jpg")
  },
  {
    name: "Miss World",
    incredients: "Beeren, Vanille Protein, Banane, Milch, Ice",
    kpis: "100ml, 150 kcal",
    category: "Energy Smoothies",
    alergenes: "G, F",
    attributes: ["gluten"],
    allergenes: "G, F",
    image: require("../../src/images/produkte/happyflirt.jpg")
  },
  {
    name: "Mister Universum",
    incredients: "Müsli, Vanille Protein, Banane, Milch, Ice",
    kpis: "100ml, 171 kcal",
    category: "Energy Smoothies",
    allergenes: "A, E, F, G, H",
    attributes: [],
    image: require("../../src/images/produkte/misteruniversum.jpg")
  },
  {
    name: "Immunity Boost",
    incredients: "Ingwer, Orange, Zitrone",
    kpis: "100ml, 47 kcal",
    category: "Mega Hot Shots",
    attributes: ["vegan", "lactose", "gluten"],
    image: require("../../src/images/produkte/shots.jpg")
  },
  {
    name: "Stay Up Shot",
    incredients: "Apfel, Matcha",
    kpis: "100ml, 69 kcal",
    category: "Mega Hot Shots",
    attributes: ["vegan", "lactose", "gluten"],
    image: require("../../src/images/produkte/shots.jpg")
  },
  {
    name: "Sweet Hot Shot",
    incredients: "Apfel, Ingwer",
    kpis: "100ml, 62 kcal",
    category: "Mega Hot Shots",
    attributes: ["vegan", "lactose", "gluten"],
    image: require("../../src/images/produkte/shots.jpg")
  },
  {
    name: "Tripple C Power",
    incredients: "Ingwer, Kurkuma, Orange, schw. Pfeffer, Zitrone",
    kpis: "100ml, 49 kcal",
    category: "Mega Hot Shots",
    attributes: ["vegan", "lactose", "gluten"],
    image: require("../../src/images/produkte/shots.jpg")
  },
  {
    name: "Wake Up Shot",
    incredients: "Apfel, Guarana",
    kpis: "100ml, 67 kcal",
    category: "Mega Hot Shots",
    attributes: ["vegan", "lactose", "gluten"],
    image: require("../../src/images/produkte/shots.jpg")
  }
];

const snacks = [
  {
    name: "Veggie Burger",
    incredients: "Gemüse Laibchen, Honig-Senf Sauce, Zwiebel, Rucola, Tomaten",
    attributes: ["veggie"],
    image: require("../../src/images/produkte/veggieburger.jpg")
  },
  {
    name: "Avocado Burger Curry",
    incredients: "Avocado, Salat, Paprika, Rucola, Curry, Humus, Zucchini",
    attributes: ["vegan", "lactose"],
    image: require("../../src/images/produkte/avocadoburgercurry.jpg")
  },
  {
    name: "Ziegen Burger",
    incredients: "Ziegenkäse, Süßkartoffeln, Rucola, Tomaten, süße Chili Sauce",
    attributes: ["veggie"],
    image: require("../../src/images/produkte/ziegenburger.jpg")
  },
  {
    name: "Greek Burger",
    incredients: "Schafskäse, Tomaten Pesto, Gurke, Oliven, Rucola, Oregano",
    attributes: ["veggie"],
    image: require("../../src/images/produkte/greekburger.jpg")
  },
  {
    name: "Avocado Burger mediterran",
    incredients:
      "Avocado, Salat, Paprika, Rucola, Basilikum-Tomaten Aufstrich, Zucchini",
    attributes: ["vegan", "lactose"],
    image: require("../../src/images/produkte/avocadoburgermediterran.jpg")
  },
  {
    name: "Avocado Burger Pesto",
    incredients: "Avocado, Salat, Paprika, Rucola, Basilikum Pesto, Zucchini",
    attributes: ["veggie"],
    image: require("../../src/images/produkte/avocadoburgerpesto.jpg")
  }
  // {
  //   name: "o´mellis Balls",
  //   incredients:
  //     "- Kokos, Datteln, Haferflocken <br>- Hanf, Datteln, Kokos, Kürbiskerne <br>- Mohn, Mandeln, Datteln, Mandelmilch",
  //   longText: true,
  //   htmlText: true,
  //   attributes: ["vegan", "lactose", "gluten", "zucker"],
  //   image: require("../../src/images/produkte/omellisballs.jpg")
  // }
];

const attributeIcons = {
  vegan: require("../../src/images/icons/product-vegan.png"),
  lactose: require("../../src/images/icons/product-lactose.png"),
  gluten: require("../../src/images/icons/product-gluten.png"),
  veggie: require("../../src/images/icons/product-veggie.png"),
  zucker: require("../../src/images/icons/product-zucker.png")
};

export { smoothies, snacks, attributeIcons };
